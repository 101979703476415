import React from "react"
import "antd/dist/antd.css";
import {ArrowLeftOutlined} from '@ant-design/icons'
import { Form, Input, Button, Select, Tooltip, Divider, Space, message } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
require('./sub.routes.css')
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const DealerCreate=()=>{
    const location=useLocation()
    const [form] = Form.useForm();
    const navigation=useNavigate()
    const onFinish = (values) => {
      const hide = message.loading('Action in progress..', 0);
      if(location.state){
        axios.put('https://activatewm.com/demo/v1/dealer/update',{
          _id:location.state.data._id,
          data:{
          email:values['email'],
          mobile:values['mobile'],
          dealership:values['Dealership'],
          dealer_name:values['Name'],
          status:values['Status']=="active"?true:false
        }
        },{
           headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(data=>{
          console.log('ss')
          if(data.data.success){
           setTimeout(hide,100)
            message.success('Updated')
          }
        }).catch(err=>{
          setTimeout(hide,1000)
          err.response.status===400?message.warning('Already sisi with same name'):message.error('Server error occur')
        })
      }else{
        axios.post('https://activatewm.com/demo/v1/dealer/create',{
          email:values['email'],
          mobile:values['mobile'],
          dealership:values['Dealership'],
          dealer_name:values['Name'],
          status:values['Status']=="active"?true:false
        },{
           headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(data=>{
          if(data.data.success){
           setTimeout(hide,100)
            message.success('Created')
          }
        }).catch(err=>{
          setTimeout(hide,1000)
          err.response.status===400?message.warning('Already Present with same name'):message.error('Server error occur')
        })
      }
     
      };
    
const onReset = () => {
        form.resetFields();
      };
    
    return (
      <div className="main-create2 w3-card" >
      <div className="header">
      <div className="back">
      <Tooltip title="Back">
      <Button type="primary" shape="circle" icon={<ArrowLeftOutlined />} onClick={()=>{
         navigation('/dealers',{replace:true})
      }}/>
    </Tooltip>    
      </div>
      </div>
      <Space />
      <Space />
      <Divider /> 
      <div className="data-form">
      <Form 
      initialValues={location.state?{"Dealership":location.state.data.dealership,
      "Name":location.state.data.dealer_name,'email':location.state.data.email,
      "mobile":location.state.data.mobile,'Status':location.state.data.status?"active":"deactive"
      }:""} {...layout} form={form} name="control-hooks" onFinish={onFinish}>
      <Form.Item
        name="Dealership"
        label="Dealership"
        rules={[
          {
            required: true,
          },
        ]}
      ><Input />
      </Form.Item>
     <Form.Item
        name="Name"
        label="Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
      <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
          },
        ]}
      >
      <Input />
      </Form.Item> 
      <Form.Item
        name="mobile"
        label="Mobile"
        rules={[
          {
            required: true,
          },
        ]}
      >
      <Input />
      </Form.Item> 
      <Form.Item
        name="Status"
        label="Status"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select a option and change input text above"
        >
          <Option value="active">Active</Option>
          <Option value="deactive">Deactive</Option>
        </Select>
      </Form.Item>
      <Space />
      <Space />
      <Divider /> 
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" style={{marginRight:10}}>
          {location.state?"Update":"Add"}
        </Button>
        <Button htmlType="button" onClick={onReset}>
          Reset
        </Button>
       
      </Form.Item>
    </Form>
      </div>
     </div>
      )
}
export default DealerCreate

